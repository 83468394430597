@tailwind base;
@tailwind utilities;
@tailwind components;

body {
  margin: 0;
  font-family: "Metropolis";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.bg-main {
  background:
    linear-gradient(17deg,rgba(58,171,214,0.2) 0%,rgba(255,255,250,1) 75%)
};
.bg-footer-gradient {
  background: conic-gradient(
    from 0deg at 96% 65%,
    rgba(255, 255, 255, 1) 24%,
    rgba(13, 23, 56, 1) 25%,
    rgba(13, 22, 56, 1) 34%
  );
}
.shadow-logo {
  filter: drop-shadow(1px 2px 12px rgb(67, 210, 230));
}
